<template>
  <BaseModular subTitle="CA办理指南" title="">
    <div class="base">
      <div class="guide">
        <div class="content" v-html="applyGuide.content"></div>
      </div>
      <div class="apply">
        <el-button type="primary" size="large" @click="toForm"
          >立即办理</el-button
        >
      </div>
    </div>
  </BaseModular>
</template>
<script>
import { storeToRefs } from "pinia";
import { caApplyStore } from "@/store";
import BaseModular from "@/components/BaseModular/BaseModular";
import { getApplyGuide } from "@/api/caApply/caApply";
import { onBeforeMount, computed, reactive } from "vue";

export default {
  components: { BaseModular },
  setup() {
    const caStore = caApplyStore();
    // const { applyGuide } = storeToRefs(caStore);
    const getData = () => {
      getApplyGuide().then((rsp) => {
        caStore.setapplyGuide(rsp.data);
      });
    };
    const toForm = () => {
      caStore.changeView(1);
    };
    const applyGuide = computed(() => storeToRefs(caStore).applyGuide);
    onBeforeMount(() => {
      getData();
    });

    const data = reactive({
      applyGuide,
    });
    return {
      ...data,
      getData,
      toForm,
    };
  },
};
</script>

<style scoped>
@import url("./CaApplyGuide.css");
</style>
  